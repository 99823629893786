<template>
  <div class="tibox">
    <div class="timain">
      <div class="tibiao">
        <el-table :data="tilist" stripe height="90%" width="100%">
          <el-table-column label="账期">
            <template #default="scope">
              {{ scope.row.title }}
            </template>
          </el-table-column>
          <el-table-column label="起始时间">
            <template #default="scope">
              {{ scope.row.st }}
            </template>
          </el-table-column>
          <el-table-column label="结束时间">
            <template #default="scope">
              {{ scope.row.et }}
            </template>
          </el-table-column>
          <el-table-column label="平台收入">
            <template #default="scope">
              <el-popover
                placement="top-start"
                width="auto"
                height="auto"
                trigger="hover"
              >
                <div class="tishi" style="text-align: center">查看明细</div>
                <template #reference>
                  <div class="pricetit" @click="gd(scope.row.st, scope.row.et)">
                    {{ scope.row.in }}元
                  </div>
                </template>
              </el-popover>
            </template>
            <!-- 
              {{ scope.row.in }}元
            </template> -->
          </el-table-column>

          <el-table-column label="平台支出">
            <template #default="scope">
              <el-popover
                placement="top-start"
                width="auto"
                height="auto"
                trigger="hover"
              >
                <div class="tishi" style="text-align: center">查看明细</div>
                <template #reference>
                  <div
                    class="pricetit"
                    @click="gd1(scope.row.st, scope.row.et)"
                  >
                    {{ scope.row.out }}元
                  </div>
                </template>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="可提现金额">
            <template #default="scope"> {{ scope.row.tixian }}元 </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" width="100">
            <template #default="scope">
              <el-button
                link
                type="primary"
                v-if="scope.row.tixian > 0"
                size="small"
                @click="tixian(scope.row.tixian)"
                >提现</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
  <deposit :isTi="istixian" :tiprice="tixianmoney"></deposit>
</template>

<script>
import { ref } from "vue";
import * as api2 from "../api/index.js";
import * as api from "../api/join.js";
import * as api1 from "../api/order";
import * as ola from "../api/olawebsock.js";
import Part from "../components/part.vue";
import deposit from "../components/deposit.vue";
import { ElMessageBox } from "element-plus";
const value = ref("");
export default {
  components: { Part, deposit },
  data() {
    return {
      value,
      value1: "",
      value2: "",
      value3: "",
      keyword: "",
      total: "",
      tilist: [],
      tilist1: [],
      techlist: [],
      clook: "",
      crescueId: "",
      emicreg: "",
      etcprice: "",

      keytype: "",
      keytit: "",
      chanliang: "",
      chanzhi: "",
      yeji: "",
      tech: "",
      name12: "",
      name11: "",
      name10: "",
      name9: "",
      name8: "",
      name6: "",
      name5: "",
      name4: "",
      name3: "2023年3月",
      name2: "2023年2月",
      name1: "2023年1月",
      a2: false,
      a3: false,
      a4: false,
      a5: false,
      a6: false,
      a7: false,
      a8: false,
      a9: false,
      a10: false,
      a11: false,
      a12: false,
      istixian: 0,
      tixianmoney: "",
      isnew: false,
    };
  },
  async created() {
    this.res_getList();
    this.gettechlist();
  },

  methods: {
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] += "";
        } else {
          sums[index] = "";
        }
      });

      return sums;
    },
    gd(st, et) {
      let a = st.replace(" 00:00:00", "");
      let b = et.replace(" 23:59:59", "");

      if (this.isnew) {
        this.$router.push("/FinanceNew?st=" + a + "&et=" + b + "&type=1");
      } else {
        this.$router.push("/Finance?st=" + a + "&et=" + b + "&type=1");
      }
    },
    gd1(st, et) {
      let a = st.replace(" 00:00:00", "");
      let b = et.replace(" 23:59:59", "");

      if (this.isnew) {
        this.$router.push("/FinanceNew?st=" + a + "&et=" + b + "&type=2");
      } else {
        this.$router.push("/Finance?st=" + a + "&et=" + b + "&type=2");
      }
    },
    async res_getList() {
      let res_getList = await api.getBill();

      this.tilist = res_getList.data;
      this.isnew = res_getList.isNew;
    },
    async gettechlist() {
      let res = await api2.gettechlist(1);
      this.techlist = res.data;
    },
    jion(id) {
      this.crescueId = id;
      this.clook++;
      if (this.clook > 2) {
        this.clook = 1;
      }
    },
    async keyword1() {
      this.datas.carnumber = this.keytit;
      this.res_getCommission(this.datas);
    },
    async daochu() {
      let res = await api1.commissionlistExport(this.datas);
    },
    call(tel, str) {
      let ue =  this.$store.state.emic;
      let mode = this.$store.state.emicmode;

      console.log("拨打电话", tel);
      ue.call.callout({
        customerNumber:tel.toString(),
        loginType:mode,
        extras:str
      });
    },
    tixian(val) {
      this.tixianmoney = val;
      this.istixian++;
      if (this.istixian == 3) {
        this.istixian = 1;
      }
    },
  },
  computed: {
    getEmic() {
      return this.$store.state.emic;
    },
    getisFin() {
      return this.$store.state.isFin;
    },
  },
  watch: {},
};
</script>

<style>
.el-table thead.is-group th.el-table__cell {
  background-color: #f5f5f5;
  color: #666;
}
.monthtit {
  height: 40px !important;
  text-align: center !important;
}
</style>
<style lang="less" scoped>
.tibiao /deep/ .el-table .cell {
  white-space: pre-line;
}
.tibox {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.timain {
  width: 1730px;
  height: 100%;
  margin-left: 190px;
  padding: 30px;
  box-sizing: border-box;
  background-color: #f8fafc;
}
.tinav {
  display: flex;
  align-items: center;
}
.tihead {
  width: 100%;
  height: 120px;
  background-color: #fff;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}
.left {
  float: left;
  // width: 300px;
  height: 32px;
  background: #ffffff;
  // border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
  position: relative;
  box-sizing: border-box;
  margin-left: 20px;
}
.jing {
  float: left;
  width: 32px;
  height: 32px;
  background: #f7f9fb;
  border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
  position: absolute;
  top: -1px;
  right: -1px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.icon {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  opacity: 1;
}
.tisou,
.tiru {
  width: 100%;
  height: 100%;
  border: 0;
  box-sizing: border-box;
  padding-left: 12px;
  outline: none;
}
.tiru {
  border: 1px solid #ccc;
}
.tishu {
  width: 250px;
  height: 32px;
  box-sizing: border-box;
  margin-left: 20px;
}
.btn {
  float: right;
  // width: 180px;
  height: 32px;
  opacity: 1;
  box-sizing: border-box;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border-radius: 2px;
  border: 0;
  cursor: pointer;
  box-sizing: border-box;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  margin-left: 20px;
  border: 1px solid #727e96;
  opacity: 1;
  border-radius: 2px;
  cursor: pointer;
  box-sizing: border-box;
}
.tibiao {
  width: 100%;
  //   height: 665px;
  height: 100%;
  background-color: #fff;
  padding: 15px;
  box-sizing: border-box;
  //   margin-top: 20px;
}
.tibiao /deep/ .el-table th.el-table__cell.is-leaf {
  background-color: #f5f5f5;
  height: 40px !important;
  color: #000;
}
.tibiao /deep/ .el-table thead.el-table__cell.is-leaf {
  background-color: #f5f5f5;
  height: 40px !important;
  color: #000;
}
.tibiao /deep/ .el-table__row {
  height: 70px !important;
  box-sizing: border-box;
}

.tibiao /deep/ .el-table__cell {
  padding: 0;
}
.jinXing-sorter {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 40px;
  bottom: 50px;
}
.call {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: url("../assets/img/index/call.png");
  background-size: contain;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  vertical-align: middle;
  margin-left: 5px;
}
.carcall {
  display: flex;
  align-items: center;
}
.left /deep/ .el-select .el-input {
  width: 200px;
  margin-right: 20px;
}
.tibiao /deep/ .el-table-fixed-column--right:last-of-type {
  // width: 200px;
  // display: flex;
  // align-items: center;
  height: 70px !important;
}
.etcprice {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-right: 30px;
  color: #606266;
}

.seek {
  float: left;
  // width: 200px;
  // height: 40px;
  background: #ffffff;
  // border: 1px solid #dadfe6;
  opacity: 1;
  box-sizing: border-box;
  border-radius: 2px;
  position: relative;
  display: flex;
}
.sekint {
  float: left;
  width: 200px;
  height: 37px;
  padding: 15px;
  outline: none;
  border: none;
  box-sizing: border-box;
  height: 32px;
  border: 1px solid #dadfe6;
}
.glass {
  // float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 30px;
  margin-top: -1px;
  background: #f7f9fb;
  // border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
  position: absolute;
  top: 2px;
  right: 1px;
  cursor: pointer;
}
.icon {
  width: 14px;
  height: 14px;
  // margin-left: 13px;
  // margin-top: 13px;
  cursor: pointer;
}

.chaxun {
  width: 80px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2c68ff;
  color: #fff;
  border: 0;
  cursor: pointer;
}
.tinav /deep/ .el-range-editor.el-input__inner {
  width: 250px;
  margin: 0 !important;
}
.tinav /deep/ .el-form-item {
  margin: 0 !important;
  margin-right: 20px !important;
}
.xiangqing {
  font-style: normal;
  color: #2c68ff;
  cursor: pointer;
}
.tiaobtn {
  margin-right: 10px;
}
.tibiao /deep/ .el-table__footer-wrapper tbody td.el-table__cell {
  font-size: 15px;
  color: #000;
  font-weight: bold;
}
.pricetit {
  cursor: pointer;
  width: 80px;
}
</style>
